import { Heading } from '@chakra-ui/react'
import { useBrowser } from '../hooks/useBrowser.js'

export const FancyHeading = ({ children, ...rest }) => {
  const { os } = useBrowser()

  return (
    <Heading
      bgGradient='linear(to-l, #7928CA, #FF0080)'
      bgClip='text'
      fontWeight={os === 'windows' ? 'bold' : 'extrabold'}
      lineHeight={os === 'windows' ? 1.5 : undefined}
      width='fit-content'
      {...rest}
    >
      {children}
    </Heading>
  )
}
