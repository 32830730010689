// TODO: publish to npm

export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY
export const MONTH = 30 * DAY
export const YEAR = 365 * DAY

const rtfByLanguage = {}

export const relativeTime = ({
  to = new Date(),
  from = new Date(),
  language = 'en-US'
} = {}) => {
  if (!(to instanceof Date)) {
    to = new Date(to)
  }
  if (!(from instanceof Date)) {
    from = new Date(from)
  }

  if (
    typeof Intl !== 'object' ||
    typeof Intl.RelativeTimeFormat !== 'function'
  ) {
    // No support in Safari 13 and other browsers we don't support
    return to.toLocaleString(language)
  }

  let rtf = rtfByLanguage[language]
  // Lazily init when first used
  if (!rtf) {
    rtf = new Intl.RelativeTimeFormat(language, {
      localeMatcher: 'best fit',
      numeric: 'auto',
      style: 'long'
    })
    rtfByLanguage[language] = rtf
  }

  let delta = Math.abs(from.valueOf() - to.valueOf())

  let unit

  switch (true) {
    case delta < 10 * SECOND: {
      unit = 'second'
      delta = 0
      break
    }
    case delta < MINUTE: {
      unit = 'second'
      delta = Math.round(delta / SECOND)
      break
    }
    case delta < HOUR: {
      unit = 'minute'
      delta = Math.round(delta / MINUTE)
      break
    }
    case delta < DAY: {
      unit = 'hour'
      delta = Math.round(delta / HOUR)
      break
    }
    case delta < WEEK: {
      unit = 'day'
      delta = Math.round(delta / DAY)
      break
    }
    case delta < MONTH: {
      unit = 'week'
      delta = Math.round(delta / WEEK)
      break
    }
    case delta < YEAR: {
      unit = 'month'
      delta = Math.round(delta / MONTH)
      break
    }
    default: {
      unit = 'year'
      delta = Math.round(delta / YEAR)
    }
  }

  const count = Math.sign(to.valueOf() - from.valueOf()) * delta
  try {
    return rtf.format(count, unit)
  } catch {
    // Some old Android devices tend to throw; do something reasonable instead
    return to.toLocaleString(language)
  }
}
