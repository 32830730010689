import { Button, Icon, useClipboard } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { CopyIcon } from '../icons/CopyIcon.js'
import { useBreakpointValue } from '../../hooks/useBreakpointValue.js'

export const CopyButton = ({
  buttonProps = () => ({}),
  onClick = () => {},
  size,
  text,
  ...rest
}) => {
  const { t } = useTranslation()
  const { hasCopied, onCopy } = useClipboard(text, { format: 'text/plain' })

  size = useBreakpointValue(size)

  const iconProps = {
    xs: {
      boxSize: 4
    },
    sm: {
      boxSize: 4
    },
    md: {
      boxSize: 5
    },
    lg: {
      boxSize: 6
    }
  }

  const { boxSize } = iconProps[size]

  const handleClick = () => {
    onCopy()
    onClick()
  }

  return (
    <Button
      leftIcon={<Icon as={CopyIcon} boxSize={boxSize} />}
      onClick={handleClick}
      size={size}
      {...rest}
      {...buttonProps(hasCopied)}
    >
      {hasCopied ? t('share.copied') : t('share.copyLink')}
    </Button>
  )
}
