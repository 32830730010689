import {
  Box,
  Center,
  Collapse,
  Grid,
  Heading,
  Progress,
  ScaleFade,
  Stack,
  Text
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import {
  maxRoomCloudSizeGb,
  maxRoomDownloadsValues,
  roomLifetimeValues
} from '../config.js'

import { Check } from './Check.js'
import { FancyHeading } from './FancyHeading.js'
import { Panel } from './Panel.js'
import { Share } from './Share.js'
import { TooltipBox } from './TooltipBox.js'
import { Trans, useTranslation } from 'react-i18next'
import { AsyncSelect } from './AsyncSelect.js'
import { RelativeTime } from './RelativeTime.js'

export const CreatePanel = ({
  cloudState,
  peerState,
  createProgress,
  roomMeta,
  shareUrl,
  filesLength,
  handleRoomLifetimeChange,
  handleMaxRoomDownloadsChange,
  ...rest
}) => {
  const { t } = useTranslation()
  const { numDownloadingPeers, lifetime, maxDownloads } = roomMeta

  const showProgress =
    peerState !== 'active' ||
    (cloudState !== 'uploaded' && cloudState !== 'upload-failed')

  const roomCreationTime =
    roomMeta.expiresAtTimestampMs - roomMeta.lifetime * 1000

  return (
    <Panel>
      <Stack spacing={[10, null, 12]} align='center' {...rest}>
        <FancyHeading textAlign='center' fontSize={['4xl', null, '5xl']}>
          {t('create.filesReady', {
            count: filesLength
          })}
        </FancyHeading>

        <Collapse in={numDownloadingPeers > 0} unmountOnExit>
          <Heading textAlign='center' fontSize={['lg', 'xl', '2xl']}>
            {t('create.numDownloadingPeers', {
              count: numDownloadingPeers
            })}
          </Heading>
        </Collapse>

        <Stack w='full' align='center' spacing={4}>
          <Heading fontSize={['lg', 'xl', '2xl']}>
            {t('create.copyLink', {
              count: filesLength
            })}
          </Heading>

          <Share
            url={shareUrl}
            w='full'
            maxW='3xl'
            size={['md', null, 'lg']}
            justify='center'
          />
        </Stack>

        <Box textAlign='center'>
          <Trans
            t={t}
            i18nKey='create.customExpiration'
            count={filesLength}
            components={{
              lifetimeselect: (
                <AsyncSelect
                  size='md'
                  display='inline-block'
                  width='auto'
                  onChange={v => handleRoomLifetimeChange(Number(v))}
                  defaultValue={lifetime}
                  errorMessage={t('createPanel.lifetimeSelect.errorTitle')}
                >
                  {roomLifetimeValues.map(value => (
                    <option key={value} value={value}>
                      {RelativeTime({
                        from: roomCreationTime,
                        to: roomCreationTime + value * 1000 - 1,
                        onlyText: true
                      })}
                    </option>
                  ))}
                </AsyncSelect>
              ),
              maxdownloadselect: (
                <AsyncSelect
                  size='md'
                  display='inline-block'
                  width='auto'
                  onChange={v => handleMaxRoomDownloadsChange(Number(v))}
                  defaultValue={maxDownloads}
                  errorMessage={t(
                    'createPanel.maxRoomDownloadsSelect.errorTitle'
                  )}
                >
                  {maxRoomDownloadsValues.map(value => (
                    <option key={value} value={value}>
                      {t('createPanel.maxRoomDownloadsSelect.label', {
                        count: value
                      })}
                    </option>
                  ))}
                </AsyncSelect>
              )
            }}
          />
        </Box>

        <Grid minH={[8, 8, 20]}>
          <Center gridColumn={1} gridRow={1}>
            <ScaleFade in={showProgress} unmountOnExit>
              <CreateProgress peerState={peerState} progress={createProgress} />
            </ScaleFade>
          </Center>
          <Center gridColumn={1} gridRow={1}>
            <ScaleFade in={!showProgress} unmountOnExit>
              <CreateDone cloudState={cloudState} />
            </ScaleFade>
          </Center>
        </Grid>
      </Stack>
    </Panel>
  )
}

const CreateProgress = ({ peerState, progress }) => {
  const { t } = useTranslation()
  if (progress == null) progress = 0

  const [isIndeterminate, setIsIndeterminate] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsIndeterminate(false)
    }, 5_000)
    return () => {
      clearTimeout(timeout)
      setIsIndeterminate(true)
    }
  }, [])

  return (
    <Stack align='center' spacing={2}>
      <Heading fontSize='lg' pb={1}>
        {peerState === 'inactive'
          ? t('create.encrypting')
          : t('create.uploading')}
      </Heading>
      <Progress
        size='sm'
        hasStripe
        isAnimated
        isIndeterminate={isIndeterminate}
        value={progress * 100}
        w='full'
        borderRadius='full'
      />
      <Text textAlign='center' color='whiteAlpha.600'>
        {t('create.downloadBeforeFinish')}
      </Text>
    </Stack>
  )
}

const CreateDone = ({ cloudState }) => {
  const { t } = useTranslation()

  return (
    <Stack direction={['column', 'row']} justify='center' spacing={[8, 4, 12]}>
      <TooltipBox label={t('create.encryptedTooltip')}>
        <Check
          title={t('create.encrypted')}
          size={['md', 'sm', 'lg']}
          description={t('create.encryptedDescription')}
        />
      </TooltipBox>

      {cloudState === 'uploaded' && (
        <TooltipBox label={t('create.uploadedTooltip')}>
          <Check
            title={t('create.uploaded')}
            size={['md', 'sm', 'lg']}
            description={t('create.uploadedDescription')}
            colorScheme='blue'
          />
        </TooltipBox>
      )}
      {cloudState === 'upload-failed' && (
        <TooltipBox
          label={t('create.uploadFailedTooltip', { maxRoomCloudSizeGb })}
        >
          <Check
            title={t('create.directTransfer')}
            size={['md', 'sm', 'lg']}
            description={t('create.uploadFailedDescription')}
            colorScheme='orange'
          />
        </TooltipBox>
      )}
    </Stack>
  )
}
