import { Box } from '@chakra-ui/react'

export const Panel = props => (
  <Box
    px={[6, 8, 10, 12]}
    py={[10, null, null, 12]}
    backgroundColor='blackAlpha.800'
    borderRadius='2xl'
    {...props}
  />
)
