import {
  chakra,
  Box,
  Stack,
  SlideFade,
  Heading,
  forwardRef
} from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { useBreakpointValue } from '../hooks/useBreakpointValue.js'

const MotionBox = motion(Box)

export const Check = forwardRef(
  (
    { title, description, colorScheme = 'green', size = 'md', ...rest },
    ref
  ) => {
    size = useBreakpointValue(size)

    const sizes = {
      sm: {
        checkmarkSize: 10,
        headingFontSize: 'xl',
        descriptionFontSize: 'sm',
        spacing: 2
      },
      md: {
        checkmarkSize: 12,
        headingFontSize: 'xl',
        descriptionFontSize: 'md',
        spacing: 4
      },
      lg: {
        checkmarkSize: 14,
        headingFontSize: '2xl',
        descriptionFontSize: 'md',
        spacing: 4
      }
    }

    const {
      checkmarkSize,
      headingFontSize,
      descriptionFontSize,
      spacing
    } = sizes[size]

    return (
      <Stack
        direction='row'
        align='center'
        spacing={spacing}
        ref={ref}
        {...rest}
      >
        <Checkmark size={checkmarkSize} color={`${colorScheme}.400`} />
        <SlideFade in offsetX={-30} offsetY={0} delay={0.1}>
          <Stack spacing={1}>
            <Heading
              fontSize={headingFontSize}
              color={`${colorScheme}.300`}
              fontWeight='extrabold'
              lineHeight={1}
              position='relative'
            >
              {title}
            </Heading>
            <Box
              color={`${colorScheme}.50`}
              fontSize={descriptionFontSize}
              maxW={64}
            >
              {description}
            </Box>
          </Stack>
        </SlideFade>
      </Stack>
    )
  }
)

export const Checkmark = ({ size = 150, color = 'green.400', ...props }) => {
  const boxVariants = {
    hide: { scale: 0, opacity: 0 },
    show: { scale: 1, opacity: 1 }
  }

  const checkVariants = {
    hide: { pathLength: 0 },
    show: { pathLength: 0.9 }
  }

  return (
    <MotionBox
      borderRadius='full'
      backgroundColor={color}
      initial='hide'
      animate='show'
      variants={boxVariants}
      transition={{ type: 'spring', stiffness: 200, damping: 20 }}
      {...props}
    >
      <chakra.svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 150 150'
        height={size}
        width={size}
      >
        <motion.path
          d='M38 74.707l24.647 24.646L116.5 45.5'
          fill='transparent'
          strokeWidth='20'
          stroke='white'
          strokeLinecap='round'
          variants={checkVariants}
          transition={{ type: 'spring', stiffness: 80, damping: 20 }}
        />
      </chakra.svg>
    </MotionBox>
  )
}
